
/* ##### 13.0 Team Area CSS ##### */

.HomeDemo1 .single-team-member {
    position: relative;
    z-index: 1;
    padding: 0 30px;
    margin-bottom: 100px;
}

.HomeDemo1 .team-member-thumb {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    margin:0 auto;
    margin-bottom: 20px;
}

.HomeDemo1 .team-info h5 {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
}

.HomeDemo1 .team-info p {
    color: #ccc;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 0;
    text-align: center;
}
.HomeDemo1 .team-social-icon{
    text-align: center;
}
.HomeDemo1 .team-social-icon a {
    font-size: 15px;
    margin-top: 10px;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
    color: cyan;
}