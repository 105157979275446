/* ##### Service Area CSS ##### */

.HomeDemo1 .service_single_content {
    position: relative;
    z-index: 1;
    padding: 30px 20px 15px;
    border: 1px solid #08b1b1;
    background: rgba(0,0,0,0.1);
    border-radius: 0 20px;
    margin-bottom: 30px;
}

.HomeDemo1 .service_single_content .service_icon i {
    font-size: 30px;
    margin-bottom: 20px;
    color: #fff;
    display: block;
}
.HomeDemo1 .service_icon{
    margin-bottom: 20px
}
.HomeDemo1 .service_single_content h6 {
    margin-bottom: 15px;
    color: #fff;
}